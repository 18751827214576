/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent, ProgressBar, Spinner, Tag } from '@blueprintjs/core';

const Data = (props) => (
  <div className="dataContainer">
    {props.dataFileQuery.error ? (
      <span>Error getting Data file link</span>
    ) : (
      <a href={props.dataFileQuery.data}>
        <Button className="downloadEmailFileButton" icon="download">
          Download Data File
        </Button>
      </a>
    )}
    <div className="runDetails">
      {props.downloadProgress === null && !props.runDetails.isLoading && !props.runDetails.isError && (
        <Tag
          icon={props.dataUpdateStatus.upToDate ? 'updated' : 'outdated'}
          intent={props.dataUpdateStatus.upToDate ? Intent.SUCCESS : Intent.WARNING}
          minimal
          round
        >
          {props.dataUpdateStatus.upToDate ? 'Up to date' : 'Update available'}
        </Tag>
      )}
      {props.runDetails.isLoading && <Spinner size={20} />}
    </div>
    {props.downloadProgress !== null ? (
      <>
        <span className="downloadProgressText">{`Downloading latest data (${Math.floor(props.downloadProgress * 100)}%)`}</span>
        <ProgressBar className="progressBar" value={props.downloadProgress} intent={Intent.PRIMARY} />
      </>
    ) : (
      <Button className="refreshButton" onClick={props.refreshData} intent={Intent.PRIMARY} icon="refresh" text="Refresh Data" />
    )}
  </div>
);

Data.propTypes = {
  dataUpdateStatus: PropTypes.shape({
    lastDateRemote: PropTypes.string,
    lastDateLocal: PropTypes.string,
    upToDate: PropTypes.bool,
  }).isRequired,
  runDetails: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.objectOf(PropTypes.string),
    err: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
  dataFileQuery: PropTypes.shape({
    data: PropTypes.string,
    error: PropTypes.any,
  }).isRequired,
};

Data.defaultProps = {
  downloadProgress: null,
};

export default Data;
